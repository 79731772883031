<template>
  <div class="bg">
    <div class="card">
      <div v-if="copied" class="notification success">Successfully copied to clipboard</div>
      <div v-if="error" class="notification fail">Could not copy to clipboard</div>
      <input @change="calcOswin" v-on:keyup="calcOswin" v-model="matnr" class="input margin-bottom" type="text" placeholder="Matrikelnummer"/>
      <input @change="calcOswin" v-on:keyup="calcOswin" v-model="formula" class="input" type="text" placeholder="Formel"/>
      <p class="p">m ... current digit</p>
      <p class="p margin-bottom">i ... i (starting at 1)</p>
      <div v-if="computedOutput !== ''" class="centerflex">
        <table>
          <tr><td :key="index" v-for="(number, index) in computedOutput.split(' ')"><span class="index p">x{{index + 1}}</span></td></tr>
          <tr><td class="td" :key="index" v-for="(number, index) in computedOutput.split(' ')"><h2 class="digit">{{number}}</h2></td></tr>
        </table>
      </div>
      <div class="centerflex"><div
          v-clipboard:copy="copyText"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="computedOutput !== ''"
          class="btn"
      >Copy to Clipboard</div></div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'Oswinizer',
  data: function () {
    return {
      matnr: '',
      formula: '',
      computedOutput: '',
      copyText: '',
      copied: false,
      error: false
    }
  },
  methods: {
    onCopy: function () {
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 3000)
    },
    onError: function () {
      this.error = true
      setTimeout(() => {
        this.error = false
      }, 3000)
    },
    getCopyText: function () {
      const output = this.computedOutput
      const numbers = output.split(' ')
      let toCopy = ''
      for (let i = 1; i <= numbers.length; i++) {
        toCopy += 'x' + i.toString() + (i === numbers.length ? '' : '\t')
      }
      toCopy += '\n'
      for (let i = 1; i <= numbers.length; i++) {
        toCopy += numbers[i-1] + (i === numbers.length ? '' : '\t')
      }
      return toCopy
    },
    calcOswin: function() {
      try {
        const matnr = this.matnr
        const formula = this.formula
        const Parser = require('expr-eval').Parser;
        let output = ''
        let inputArr = Array.from(matnr)
        for (let i = 0; i < inputArr.length; i++) {
          const computed = Parser.evaluate(formula, {m: parseInt(inputArr[i]), i: i + 1})
          output += computed.toString() + (i === inputArr.length - 1 ? '' : ' ')
        }
        this.computedOutput = output
        this.copyText = this.getCopyText(output)
      } catch (e) {this.computedOutput = ''}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.fail {
  background-color: #ff5555;
}

.success {
  background-color: #00e585;
}

.notification {
  color: #fff;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.index {
  color: #939597;
}

h1, h2, h3 {
  color: #939597
}

.btn {
  background-color: #2c3e50;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: .2s;
}

.btn:hover {
  background-color: #202d3a;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.5);
  box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.5);
  transition: .2s;
  cursor: pointer;
}

.p {
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  text-align: start;
}

h1 {
  text-align: center;
  color: #fff
}

.td {
  border: 1px solid #F5DF4D88;
}

.centerflex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.card {
  border-radius: 12px;
  padding: 32px;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 24px -4px rgba(0,0,0,0.5);
  box-shadow: 0px 4px 24px -4px rgba(0,0,0,0.5);
  grid-area: card;
}

.margin-bottom {
  margin-bottom: 16px;
}

.input {
  width: calc(100% - 16px);
  padding: 8px;
  border: none;
  background-color: #efefef;
  border-radius: 4px;
  font-size: 20px;
  text-align: center;
  letter-spacing: 8px;
}

.digit {
  margin: 4px 8px;
}

.input:focus {
  border: none;
  outline: none;
}

@media only screen and (max-width: 999.98px) {
  .bg {
    padding: 16px;
    background-color: #F5DF4D;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    "."
    "card"
    ".";
  }
}

@media only screen and (min-width: 1000px) {
  .bg {
    background-color: #F5DF4D;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". . ."
    ". card ."
    ". . .";
  }
}

</style>
